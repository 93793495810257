/* eslint-env browser */

import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import LabelImportant from "@mui/icons-material/LabelImportant";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const DonutChartLegend = React.forwardRef(function DonutChartLegend(
  {payload, reportId, chartId, getColor, textColor, formatter, additionalData, additionalDataPosition},
  ref
) {
  return (
    <Grid container rowSpacing={0.5} ref={ref}>
      {additionalData &&
        additionalDataPosition === "top" &&
        additionalData.map(({name, value}) => (
          <Grid item xs={12} key={name} id={`${chartId}-${name}-${reportId}`}>
            <Typography variant="body2" sx={{color: textColor}} mb={1}>
              {name}: {value}
            </Typography>
          </Grid>
        ))}

      {payload.map(({value, payload: p}, index) => (
        <Grid item xs={12} key={value} id={`${chartId}-${value}-${reportId}`}>
          <Box sx={{display: "inline-flex", alignItems: "center"}}>
            <LabelImportant sx={{color: getColor(value, index)}} />
            <Typography variant="body2" sx={{color: textColor}}>
              {value}: {formatter(p)}
            </Typography>
          </Box>
        </Grid>
      ))}

      {additionalData &&
        additionalDataPosition === "bottom" &&
        additionalData.map(({name, value}) => (
          <Grid item xs={12} key={name} id={`${chartId}-${name}-${reportId}`}>
            <Typography variant="body2" sx={{color: textColor}} mb={1}>
              {name}: {value}
            </Typography>
          </Grid>
        ))}
    </Grid>
  );
});

DonutChartLegend.propTypes = {
  payload: PropTypes.array.isRequired,
  reportId: PropTypes.string.isRequired,
  chartId: PropTypes.string.isRequired,
  getColor: PropTypes.func.isRequired,
  textColor: PropTypes.string.isRequired,
  formatter: PropTypes.func.isRequired,
  additionalData: PropTypes.array,
  additionalDataPosition: PropTypes.oneOf(["top", "bottom"]),
};
export default DonutChartLegend;
