/* eslint-env browser */
import stripViewerPug from "../../shared/stripViewer/stripViewer.pug";
import {formatDateAndTime} from "../DateAndTime/DateAndTime.jsx";

/* @ngInject */
export default class StripListController {
  constructor($scope, $mdDialog, $injector, $state, $window) {
    this._$scope = $scope;
    this._$mdDialog = $mdDialog;
    this._$state = $state;
    this._$window = $window;

    this._$rootScope = $injector.get("$rootScope");
    this._Markers = $injector.get("Markers");
    this.features = $injector.get("Config").features;

    this._stripOrder = "startTime";
    this.filterText = "";
    this.allStripsIncludedToggle = true;
    this.stripReverseSort = false;
    this.heartRateSummary = "N/A";

    this.$onInit = this._init;
  }

  /// Getters and Setters ///

  get stripOrder() {
    return this._stripOrder;
  }

  set stripOrder(columnName) {
    if (columnName === this._stripOrder) {
      this.stripReverseSort = !this.stripReverseSort;
    } else if (["isPatientActivated", "displaySER"].includes(columnName)) {
      // Start reversed so that [true] is at the top and [false] is at the bottom
      this.stripReverseSort = true;
    } else {
      this.stripReverseSort = false;
    }
    this._stripOrder = columnName;
  }

  get noResultsMessage() {
    let message;
    if (!this.filteredStrips || this.filteredStrips.length > 0) {
      message = "";
    } else if (this.filterText !== "" && this.strips.length > 0) {
      message = "No Results";
    } else {
      message = "There are no saved ECG strips for this time period";
    }
    return message;
  }

  /// Private Functions ///

  _init() {
    // Set initial state on Strip Filter
    if (this.item.category === "ECG Event" && !this.includedStrips) {
      this.filterText = "Baseline";
    }

    this.refreshFilteredStrips();
    this.ableToReviewAllStrips = this.filteredStrips && this.filteredStrips.length > 0;

    if (this.includedStrips) {
      this.filteredStrips.forEach((strip) => {
        strip.includeInReport = !!this.includedStrips[strip.id];

        if (this.includedStrips[strip.id]) {
          strip.updatedSinceReportGeneration = strip.updatedAt !== this.includedStrips[strip.id].updatedAt;
        }
      });
    }

    this.handleStripToggleChange(this.filteredStrips);

    // After initializing the heartRateSummary, set the toggle to OFF if there is no data
    if (this.chartToggles && this.heartRateSummary === "N/A") {
      this.chartToggles.heartRateSummary = false;
    }
  }

  _includeStrips(strips) {
    strips.forEach((strip) => {
      strip.includeInReport = true;
    });
  }

  _excludeStrips(strips) {
    strips.forEach((strip) => {
      strip.includeInReport = false;
    });
  }

  /// Public Functions ///
  updateStripStatuses(status, strips) {
    if (status) {
      this._includeStrips(strips);
    } else {
      this._excludeStrips(strips);
    }
    this.handleStripToggleChange(strips);
  }

  handleStripToggleChange(strips) {
    this.ableToReviewAllStrips = strips.some((s) => s.includeInReport);
    if (!this.ableToReviewAllStrips) {
      this.allStripsIncludedToggle = false;
    } else if (strips.reduce((acc, element) => acc && element.includeInReport, true)) {
      this.allStripsIncludedToggle = true;
    }

    this.updateStripsArray();
  }

  clickedViewStrip(clickEvent, strip) {
    setTimeout(this._fixMultiPopupBackDrop, 20, strip.userClassification);

    return this._$mdDialog
      .show({
        locals: {
          strips: [strip],
          showPopupCounter: false,
          study: this.item.study,
        },
        controller: "StripViewerController",
        controllerAs: "stripViewer",
        template: stripViewerPug(),
        targetEvent: clickEvent,
        multiple: true,
      })
      .then((oldOrUpdatedStrips) => this.updateStripsArray(oldOrUpdatedStrips))
      .then(() => this.refreshFilteredStrips());
  }

  clickedReviewAllStrips(clickEvent) {
    const sortFunction = (a, b) => {
      let compareValue = 0;
      if (a[this._stripOrder] > b[this._stripOrder]) {
        compareValue = 1;
      } else if (a[this._stripOrder] < b[this._stripOrder]) {
        compareValue = -1;
      }

      if (this.stripReverseSort) {
        compareValue = -compareValue;
      }
      return compareValue;
    };

    setTimeout(this._fixMultiPopupBackDrop, 20, "Strip");

    return this._$mdDialog
      .show({
        locals: {
          strips: this.filteredStrips.filter((strip) => strip.includeInReport).sort(sortFunction),
          showPopupCounter: true,
          study: this.item.study,
        },
        controller: "StripViewerController",
        controllerAs: "stripViewer",
        template: stripViewerPug(),
        targetEvent: clickEvent,
        multiple: true,
      })
      .then((oldOrUpdatedStrips) => this.updateStripsArray(oldOrUpdatedStrips))
      .then(() => this.refreshFilteredStrips());
  }

  updateStripsArray(oldOrUpdatedStrips = []) {
    oldOrUpdatedStrips.forEach((updatedStrip) => {
      const stripIndex = this.strips.findIndex((s) => updatedStrip.id === s.id);
      this.strips[stripIndex] = updatedStrip;
    });

    // Calculate the min mean, max mean, sum of means, and count
    const {min, max, sum, count} = this.strips.reduce(
      (data, strip) => {
        const {mean} = strip.measurements.HR;

        // Only consider strips that are included
        // Only consider strips that have at least one R-R
        if (strip.includeInReport && mean !== undefined) {
          data.min = Math.min(data.min, mean);
          data.max = Math.max(data.max, mean);
          data.sum += mean;
          data.count++;
        }

        return data;
      },
      {min: Infinity, max: 0, sum: 0, count: 0}
    );

    this.strips.forEach((strip) => {
      // Update and store tooltip on strip for fast access
      strip.heartRateTooltip = this.getHeartRateTooltip(strip);

      // Update and store displayed time for fast access
      strip.displayedStartTime = strip.validStartTime
        ? formatDateAndTime({datetime: strip.startTime, zone: this.item.study?.timeZone, seconds: true})
        : "Unknown";

      // Update and store boolean values on the strip for fast access
      this.updateSERIcon(strip);
      strip.showMinChip = this.showMinMaxChip(strip, min);
      strip.showMaxChip = this.showMinMaxChip(strip, max);
    });

    if (count === 0) {
      // If there are no included strips with Heart Rate, the summary should behave elegantly
      this.heartRateSummary = "N/A";
      this.heartRateTooltip = "No HR included";
    } else {
      this.heartRateSummary = `${min} - ${Math.round(sum / count)} - ${max} bpm`;
      this.heartRateTooltip = `Calculated from ${count} included HR strip${count === 1 ? "" : "s"}`;
    }
  }

  getHeartRateTooltip(strip) {
    const rrCount = strip.measurements.RR.data.length;
    const {min, max} = strip.measurements.HR;

    if (rrCount === 0) {
      return "No R-R measurements";
    }
    if (rrCount === 1) {
      return `${min} bpm (1 R-R measurement)`;
    }
    return `${min}-${max} bpm (${rrCount} R-R measurements)`;
  }

  showMinMaxChip(strip, valueToMatch) {
    if (!strip.includeInReport || !this.chartToggles?.heartRateSummary | !this.features.heartRateSummary) {
      return false;
    }

    // valueToMatch will be the min mean, or the max mean
    return strip.measurements.HR.mean === valueToMatch;
  }

  updateSERIcon(strip) {
    // Find the report that contains this strip, by id
    const foundReport = this.item.singleEpisodeReports.find((report) =>
      // This will return true if the report contains the strip in question
      report.strips.some((reportStrip) => reportStrip.id === strip.id)
    );

    // If the report exists, display the icon button and attach the report to the strip
    strip.displaySER = !!foundReport;
    strip.singleEpisodeReport = foundReport;
  }

  clickedViewSER(strip) {
    const reportId = strip.singleEpisodeReport.id;
    // open report in another tab
    const url = this._$state.href("Generated Report", {type: "single-episode", reportId});
    this._$window.open(url, "_blank");
  }

  refreshFilteredStrips(updatedText = this.filterText) {
    const lowerCaseText = updatedText.toLowerCase();
    const fieldsToFilterBy = ["comment", "userClassification", "displayedMeanHR"];
    if (lowerCaseText === "") {
      this.filteredStrips = this.strips;
    } else {
      this.filteredStrips = this.strips.filter((strip) => {
        // Show the strip if at least one of the fields contains the Filter Text
        return fieldsToFilterBy.some((field) => strip[field].toLowerCase().includes(lowerCaseText));
      });
    }

    this.handleStripToggleChange(this.filteredStrips);
  }

  onKeyPress(event) {
    // Ignore Return Key Presses
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  _fixMultiPopupBackDrop(textToSearchFor) {
    const dialogs = Array.from(document.getElementsByClassName("md-dialog-container"));

    if (dialogs.length > 1) {
      const dialog = dialogs.find((e) => e.textContent.toUpperCase().includes(textToSearchFor.toUpperCase()));
      let computedStyle = window.getComputedStyle(dialog);
      let currentIndex = Number(computedStyle.getPropertyValue("z-index"));
      dialog.style["z-index"] = `${currentIndex - 2}`;

      const dialogBackdrop = document.getElementsByClassName("md-dialog-backdrop")[1];
      computedStyle = window.getComputedStyle(dialogBackdrop);
      currentIndex = Number(computedStyle.getPropertyValue("z-index"));
      dialogBackdrop.style["z-index"] = `${currentIndex - 2}`;
    }
  }
}
