import angular from "angular";

import {angularizeDirective} from "../../../shared/react-in-angular.js";
import VentricularEctopy from "./VentricularEctopy.jsx";

export default angular.module("app.components.ventricularEctopy", []).name;

// matches <br-ventricular-ectopy>
angularizeDirective(
  VentricularEctopy,
  "brVentricularEctopy",
  angular.module("app.components.ventricularEctopy"),
  {
    id: "=",
    study: "=",
    start: "=",
    end: "=",
    toggles: "=",
  }
);
