/* eslint-env browser */

import angular from "angular";

import logo from "../../../img/br-logo-inbox.svg";

/* @ngInject */
export default class SideNavigationController {
  constructor(
    $mdSidenav,
    Config,
    Authentication,
    $window,
    $scope,
    $rootScope,
    SocketService,
    $state,
    SearchService,
    $mdToast,
    $mdDialog,
    WorkflowsService,
    NotificationService,
    InboxItemSearchParamsService,
    PubsubMessageService,
    InboxItemService,
    $transitions
  ) {
    this._$mdSidenav = $mdSidenav;
    this._Authentication = Authentication;
    this._$window = $window;
    this._$scope = $scope;
    this._$rootScope = $rootScope;
    this._SocketService = SocketService;
    this._$state = $state;
    this._Search = SearchService;
    this._$mdToast = $mdToast;
    this._$mdDialog = $mdDialog;
    this._WorkflowsService = WorkflowsService;
    this._NotificationService = NotificationService;
    this._InboxItemSearchParamsService = InboxItemSearchParamsService;
    this._PubsubMessageService = PubsubMessageService;
    this._InboxItemService = InboxItemService;
    this._$transitions = $transitions;

    this.features = Config.features;
    this.bitRhythmLogo = logo;

    this._$mdToast.hide(document.getElementsByClassName("md-toast-banner"));

    this.triageEnabledForFacility =
      this._WorkflowsService.workflowSettings[this._Authentication.getFacilityId()]?.triageEnabled;

    this.triageTotalCount = "-";
    this.inboxTotalCount = "-";
    this.notificationTotalCount = "-";
    this.patientActivatedTotalCount = "-";
    this.triagePatientActivatedTotalCount = "-";

    // Only get and update Inbox and Triage page counts if the user is not a Physician or a Clinical Staff user
    if (!this.isAuthorized(["clinicalStaff", "physician"])) {
      this.defaultTriageParams = this._InboxItemSearchParamsService.instantiate("allInboxItems", true);
      this.defaultInboxParams = this._InboxItemSearchParamsService.instantiate("allInboxItems");
      this.defaultNotificationParams = this._InboxItemSearchParamsService.instantiate("notifications");
      this.defaultPatientActivatedParams = this._InboxItemSearchParamsService.instantiate("patientActivated");

      this._updatePageCountsIfReady();

      setInterval(async () => {
        return this._updatePageCountsIfReady();
      }, 30000);
    }

    document.getElementById("mainDocument").style.paddingLeft = "225px";

    // onError is triggered when the page is clicked and already active
    this._$transitions.onError({}, (transitionStates) => {
      const error = transitionStates.error();
      if (this.pageIsSearchable() && error.message === "The transition was ignored") {
        // Clears search text and notifies watchers
        this._Search.searchText = "";
      }
    });

    this._$transitions.onStart({}, () => {
      this._Search.clearSearchText();
    });

    angular.element(this._$window).bind("resize", () => {
      this._$rootScope.$emit("window-resize");
    });
  }

  displayTriageChips() {
    return this.triageEnabledForFacility && !this.isAuthorized(["clinicalStaff", "physician"]);
  }

  displayTriageButton() {
    return (
      this.isAuthorized(["triageTech"]) || // Handle case where triage techs can log in but triage is disabled
      this.displayTriageChips()
    );
  }

  pageIsSearchable() {
    let searchable;

    try {
      searchable = this._$state.current.data.searchable;
    } catch (error) {
      searchable = false;
    }
    return searchable;
  }

  async logOut() {
    await this._Authentication.logOut();
    this._SocketService.onDisconnect(() => this._SocketService.removeConnectionListeners());
    this._SocketService.disconnect();
  }

  /**
   * Checks if the user role is within the allowed roles
   * @param {string[]} allowedRoles
   * @returns {boolean}
   */
  isAuthorized(allowedRoles) {
    return this._Authentication.isInAnyRole(allowedRoles);
  }

  displayIfAuthorized(stateName) {
    let display;
    const state = this._$state.get(stateName);

    try {
      const allowedRoles = state.data.roles;
      display = this.isAuthorized(allowedRoles);
    } catch (error) {
      display = false;
    }

    return display;
  }

  clickedNotificationChip() {
    const url = this._$state.href("analyze");

    this._$window.localStorage.setItem(
      "search",
      JSON.stringify({
        text: "is:notification",
        timeExpired: new Date().getTime() + 30000,
      })
    );

    this._$window.open(url, "_self");
  }

  clickedPatientEventChip(page) {
    this._$window.localStorage.setItem(
      "search",
      JSON.stringify({
        text: 'type:"patient activated event"',
        timeExpired: new Date().getTime() + 30000,
      })
    );

    this._$window.open(`/${page}`, "_self");
  }

  // Update Item Counts displayed in Nav bar
  async _updatePageCountsIfReady() {
    if (this.pageCountUpdateStatus === "queued") {
      // This state can only occur if a request is in progress, which will handle the
      // queue upon completing (so do nothing)
    } else if (this.pageCountUpdateStatus === "updating") {
      // This state can only occur if a request is in progress, so queue another request
      this.pageCountUpdateStatus = "queued";
    } else {
      // Handle "ready" or undefined
      this.pageCountUpdateStatus = "updating";

      await this._updatePageCounts();

      if (this.pageCountUpdateStatus === "queued") {
        // Trigger another request
        this.pageCountUpdateStatus = "ready";
        this._updatePageCountsIfReady();
      } else {
        // Return to a baseline state
        this.pageCountUpdateStatus = "ready";
      }
    }
  }

  async _updatePageCounts() {
    const requestPromises = [];

    // Only make these requests if not a triageTech (other user type logic occurs above)
    if (!this.isAuthorized(["triageTech"])) {
      requestPromises.push(
        this._getTotalCount(this.defaultInboxParams).then((count) => {
          if (count > 99) {
            this.inboxTotalCount = "99+";
          } else {
            this.inboxTotalCount = `${count}`;
          }
        }),
        this._getTotalCount(this.defaultNotificationParams).then((count) => {
          if (count > 99) {
            this.notificationTotalCount = "99+";
          } else {
            this.notificationTotalCount = `${count}`;
          }
        }),
        this._getTotalCount(this.defaultPatientActivatedParams).then((count) => {
          if (count > 99) {
            this.patientActivatedTotalCount = "99+";
          } else {
            this.patientActivatedTotalCount = `${count}`;
          }
        })
      );
    }

    // If the triage page is available, get the triage page counts
    if (this.displayTriageChips()) {
      const isTriage = true;
      requestPromises.push(
        this._getTotalCount(this.defaultTriageParams, isTriage).then((count) => {
          if (count > 99) {
            this.triageTotalCount = "99+";
          } else {
            this.triageTotalCount = count;
          }
        }),
        // Patient Activated Events
        this._getTotalCount(this.defaultPatientActivatedParams, isTriage).then((count) => {
          if (count > 99) {
            this.triagePatientActivatedTotalCount = "99+";
          } else {
            this.triagePatientActivatedTotalCount = `${count}`;
          }
        })
      );
    }

    await Promise.all(requestPromises);
    this._$scope.$apply();
  }

  _getTotalCount(searchParams, isTriage = false) {
    const queryParamsLimitZero = {...searchParams, limit: 0};
    return this._InboxItemService
      .getItems(queryParamsLimitZero, isTriage)
      .then(({totalCount}) => totalCount)
      .catch((err) => "-");
  }
}
